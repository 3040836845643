import React from "react"

import Page from "../components/Page"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Page>
    <SEO title="404: Not found" />
    <section className="section_padding">
      <div className="container">
        <div className="row justify-content-center align-items-center">
          <div className="col-lg-7">
            <div className="section_title text-center">
              <h1>NOT FOUND</h1>
              <p>You just hit a wrong note</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  </Page>
)

export default NotFoundPage
